<template>
  <div>
    <h1 style="margin-top: 80px; text-align: center">注册面板</h1>
    <a-form layout="horizontal" :form="form" style="margin-top: 30px">
      <a-form-item label="账号" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="[
            'username',
            {
              initiaValue: username,
              rules: [
                {
                  required: true,
                  min: 6,
                  max: 18,
                  message: '字符数必须在6-18之间',
                },
              ],
            },
          ]"
          size="large"
        >
          <a-icon
            slot="prefix"
            type="user"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item label="邮箱" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="[
            'email',
            {
              initiaValue: email,
              rules: [
                {
                  required: true,
                  message: '邮箱不能为空',
                },
                {
                  validator: this.checkMail.bind(this),
                },
              ],
            },
          ]"
          size="large"
        >
          <a-icon
            slot="prefix"
            type="mail"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item
        label="验证码"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-input
          v-decorator="[
            'verifcode',
            {
              initiaValue: verifcode,
              rules: [
                {
                  required: true,
                  message: '验证码不能为空',
                },
                {
                  validator: this.checkVerifcode.bind(this),
                },
              ],
            },
          ]"
          size="large"
        >
          <a-icon
            slot="prefix"
            type="qrcode"
            style="color: rgba(0, 0, 0, 0.25)"
          />
          <a-button
            v-if="second === 0"
            slot="suffix"
            type="link"
            @click="send_mail"
            >获取验证码</a-button
          >
          <span slot="suffix" v-else>{{ second }}秒后重发</span>
        </a-input>
      </a-form-item>
      <a-form-item label="密码" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input-password
          v-decorator="[
            'password',
            {
              initiaValue: password,
              rules: [
                {
                  required: true,
                  min: 6,
                  max: 18,
                  message: '字符数必须在6-18之间',
                },
                {
                  validator: this.checkPwd.bind(this),
                },
              ],
            },
          ]"
          size="large"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input-password>
      </a-form-item>
      <a-form-item
        label="再次输入密码"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-input-password
          v-decorator="[
            'repassword',
            {
              initiaValue: repassword,
              rules: [
                {
                  required: true,
                  min: 6,
                  max: 18,
                  message: '字符数必须在6-18之间',
                },
                {
                  validator: this.checkRePwd.bind(this),
                },
              ],
            },
          ]"
          size="large"
        >
          <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input-password>
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 11, span: 2 }">
        <a-button type="primary" @click="handleSubmit" size="large" block>
          注册
        </a-button>
      </a-form-item>
    </a-form>
    <div style="margin-top: 30px; text-align: center">
      <a-button type="link" @click="$router.push('/user/login')"
        >已有账号?前往登录</a-button
      >
    </div>
    <div style="margin-top: 50px; text-align: center">
      <a-button type="link" @click="toBeiAn">陕ICP备2020014834号</a-button>
    </div>
  </div>
</template>

<script>
import { register_judge, register } from "@/api/auth";
import { checkMail, checkVerifcode } from "@/api/validator";
export default {
  data() {
    this.form = this.$form.createForm(this);
    return {
      labelCol: { span: 4, offset: 4 },
      wrapperCol: { span: 8 },
      username: "",
      email: "",
      verifcode: "",
      password: "",
      repassword: "",
      second: 0,
    };
  },
  methods: {
    toBeiAn() {
      window.open("https://beian.miit.gov.cn/", "_target");
    },
    send_mail() {
      this.form.validateFields(["email"], (error) => {
        if (!error) {
          var query = {
            type: "send_mail",
            email: this.form.getFieldValue("email"),
          };
          register_judge(query).then((res) => {
            if (res.data.status === "fail") {
              this.$message.error(res.data.result);
            } else {
              this.$message.success(res.data.result);
              this.second = 60;
              this.timer = setInterval(() => {
                if (this.second === 0) clearInterval(this.timer);
                else this.second--;
              }, 1000);
            }
          });
        }
      });
    },
    checkMail(rule, value, callback) {
      let result = checkMail(value);
      if (result.length === 0) {
        callback();
      } else {
        callback(result);
      }
    },
    checkVerifcode(rule, value, callback) {
      let result = checkVerifcode(value);
      if (result.length === 0) {
        callback();
      } else {
        callback(result);
      }
    },
    checkPwd(rule, value, callback) {
      let repass = this.form.getFieldValue("repassword");
      if (
        value.length >= 6 &&
        value.length <= 18 &&
        repass.length >= 6 &&
        repass.length <= 18 &&
        value !== repass
      ) {
        callback("两次(密码输入不一致");
      } else {
        callback();
      }
    },
    checkRePwd(rule, value, callback) {
      let pass = this.form.getFieldValue("password");
      if (
        value.length >= 6 &&
        value.length <= 18 &&
        pass.length >= 6 &&
        pass.length <= 18 &&
        value !== pass
      ) {
        callback("两次密码输入不一致");
      } else {
        callback();
      }
    },
    handleSubmit() {
      this.form.validateFields((error, values) => {
        if (!error) {
          // 先检测用户名是否存在
          Object.assign(this, values);
          var query = {
            type: "user_is_exists",
            username: this.username,
          };
          register_judge(query).then((res) => {
            if (res.data.status === "fail") {
              this.$message.error(res.data.result);
            } else {
              // 用户名不存在
              var data = {
                type: "mail",
                mail: this.email,
                username: this.username,
                password: this.password,
                code: this.verifcode,
              };
              register(data).then((res) => {
                if (res.data.status === "fail") {
                  this.$message.error(res.data.result);
                } else {
                  this.$message.success(res.data.result);
                  this.$router.push("/user/login");
                }
              });
            }
          });
        }
      });
    },
  },
};
</script>
