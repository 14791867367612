export const checkMail = (value) => {
  const reg = /^[0-9a-zA-Z_]{0,19}@[0-9a-zA-Z]{1,13}\.[com,cn,net]{1,3}$/;
  if (!reg.test(value)) {
    return "邮箱格式不正确";
  } else {
    return "";
  }
};

export const checkVerifcode = (value) => {
  const reg = /^[0-9]{6}$/;
  if (!reg.test(value)) {
    return "验证码格式不正确";
  } else {
    return "";
  }
};
