<template>
  <div>
    <h1 style="margin-top: 80px; text-align: center">登录面板</h1>
    <a-row>
      <a-col :span="4" :offset="9"
        ><a-button type="link" @click="status = 'username'"
          ><span :class="status === 'username' ? 'active' : ''"
            >账号登录</span
          ></a-button
        ></a-col
      >
      <a-col :span="4"
        ><a-button type="link" @click="status = 'email'"
          ><span :class="status === 'email' ? 'active' : ''"
            >邮箱登录</span
          ></a-button
        ></a-col
      >
    </a-row>
    <div v-if="status === 'username'" style="margin-top: 30px">
      <a-form layout="horizontal" :form="form">
        <a-form-item
          label="账号"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-input
            v-decorator="[
              'username',
              {
                initiaValue: username,
                rules: [
                  {
                    required: true,
                    min: 6,
                    max: 18,
                    message: '字符数必须在6-18之间',
                  },
                ],
              },
            ]"
            size="large"
          >
            <a-icon
              slot="prefix"
              type="user"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
        <a-form-item
          label="密码"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-input-password
            v-decorator="[
              'password',
              {
                initiaValue: password,
                rules: [
                  {
                    required: true,
                    min: 6,
                    max: 18,
                    message: '字符数必须在6-18之间',
                  },
                ],
              },
            ]"
            size="large"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input-password>
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 11, span: 2 }">
          <a-button type="primary" @click="handleSubmit" size="large" block>
            登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <div v-if="status === 'email'" style="margin-top: 30px">
      <a-form layout="horizontal" :form="form">
        <a-form-item
          label="邮箱"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-input
            v-decorator="[
              'email',
              {
                initiaValue: email,
                rules: [
                  {
                    required: true,
                    message: '邮箱不能为空',
                  },
                  {
                    validator: this.checkMail.bind(this),
                  },
                ],
              },
            ]"
            size="large"
          >
            <a-icon
              slot="prefix"
              type="mail"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
        <a-form-item
          label="验证码"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-input
            v-decorator="[
              'verifcode',
              {
                initiaValue: verifcode,
                rules: [
                  {
                    required: true,
                    message: '验证码不能为空',
                  },
                  {
                    validator: this.checkVerifcode.bind(this),
                  },
                ],
              },
            ]"
            size="large"
          >
            <a-icon
              slot="prefix"
              type="qrcode"
              style="color: rgba(0, 0, 0, 0.25)"
            />
            <a-button
              v-if="second === 0"
              slot="suffix"
              type="link"
              @click="send_mail"
              >获取验证码</a-button
            >
            <span slot="suffix" v-else>{{ second }}秒后重发</span>
          </a-input>
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 11, span: 2 }">
          <a-button type="primary" @click="emailLogin" size="large" block>
            登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <div style="margin-top: 30px; text-align: center">
      <a-button type="link" @click="$router.push('/user/register')"
        >没有账号?前往注册</a-button
      >
    </div>
    <div style="margin-top: 50px; text-align: center">
      <a-button type="link" @click="toBeiAn">陕ICP备2020014834号</a-button>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { email_send_login } from "@/api/auth";
import { checkMail, checkVerifcode } from "@/api/validator";
export default {
  data() {
    this.form = this.$form.createForm(this);
    return {
      status: "username",
      labelCol: { span: 4, offset: 4 },
      wrapperCol: { span: 8 },
      email: "",
      verifcode: "",
      username: "",
      password: "",
      second: 0,
      timer: null,
    };
  },
  methods: {
    toBeiAn() {
      window.open("https://beian.miit.gov.cn/", "_target");
    },
    handleFormLayoutChange(e) {
      this.formLayout = e.target.value;
    },
    handleSubmit() {
      this.form.validateFields((error, values) => {
        if (!error) {
          Object.assign(this, values);
          var data = {
            username: this.username,
            password: this.password,
          };
          // 登录
          store.dispatch("handleLogin", { data: data }).then((res) => {
            if (res.token) {
              this.$message.success("登录成功!");
              this.$router.push("/");
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    emailLogin() {
      this.form.validateFields((error, values) => {
        if (!error) {
          Object.assign(this, values);
          var data = {
            email: this.email,
            code: this.verifcode,
          };
          // 登录
          store.dispatch("emailLogin", { data: data }).then((res) => {
            if (res.status) {
              this.$message.error(res.result);
            } else {
              this.$message.success("登录成功!");
              this.$router.push("/");
            }
          });
        }
      });
    },
    send_mail() {
      this.form.validateFields(["email"], (error) => {
        if (!error) {
          var query = {
            email: this.form.getFieldValue("email"),
          };
          email_send_login(query).then((res) => {
            if (res.data.status === "fail") {
              this.$message.error(res.data.msg);
            } else {
              this.$message.success(res.data.msg);
              this.second = 60;
              this.timer = setInterval(() => {
                if (this.second === 0) clearInterval(this.timer);
                else this.second--;
              }, 1000);
            }
          });
        }
      });
    },
    checkMail(rule, value, callback) {
      let result = checkMail(value);
      if (result.length === 0 || value.length === 0) {
        callback();
      } else {
        callback(result);
      }
    },
    checkVerifcode(rule, value, callback) {
      let result = checkVerifcode(value);
      if (result.length === 0 || value.length === 0) {
        callback();
      } else {
        callback(result);
      }
    },
  },
};
</script>

<style scoped>
.active {
  color: red;
}
</style>
