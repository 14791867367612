var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticStyle:{"margin-top":"80px","text-align":"center"}},[_vm._v("登录面板")]),_c('a-row',[_c('a-col',{attrs:{"span":4,"offset":9}},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){_vm.status = 'username'}}},[_c('span',{class:_vm.status === 'username' ? 'active' : ''},[_vm._v("账号登录")])])],1),_c('a-col',{attrs:{"span":4}},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){_vm.status = 'email'}}},[_c('span',{class:_vm.status === 'email' ? 'active' : ''},[_vm._v("邮箱登录")])])],1)],1),(_vm.status === 'username')?_c('div',{staticStyle:{"margin-top":"30px"}},[_c('a-form',{attrs:{"layout":"horizontal","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"账号","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              initiaValue: _vm.username,
              rules: [
                {
                  required: true,
                  min: 6,
                  max: 18,
                  message: '字符数必须在6-18之间',
                },
              ],
            },
          ]),expression:"[\n            'username',\n            {\n              initiaValue: username,\n              rules: [\n                {\n                  required: true,\n                  min: 6,\n                  max: 18,\n                  message: '字符数必须在6-18之间',\n                },\n              ],\n            },\n          ]"}],attrs:{"size":"large"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"label":"密码","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              initiaValue: _vm.password,
              rules: [
                {
                  required: true,
                  min: 6,
                  max: 18,
                  message: '字符数必须在6-18之间',
                },
              ],
            },
          ]),expression:"[\n            'password',\n            {\n              initiaValue: password,\n              rules: [\n                {\n                  required: true,\n                  min: 6,\n                  max: 18,\n                  message: '字符数必须在6-18之间',\n                },\n              ],\n            },\n          ]"}],attrs:{"size":"large"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"wrapper-col":{ offset: 11, span: 2 }}},[_c('a-button',{attrs:{"type":"primary","size":"large","block":""},on:{"click":_vm.handleSubmit}},[_vm._v(" 登录 ")])],1)],1)],1):_vm._e(),(_vm.status === 'email')?_c('div',{staticStyle:{"margin-top":"30px"}},[_c('a-form',{attrs:{"layout":"horizontal","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"邮箱","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              initiaValue: _vm.email,
              rules: [
                {
                  required: true,
                  message: '邮箱不能为空',
                },
                {
                  validator: this.checkMail.bind(this),
                },
              ],
            },
          ]),expression:"[\n            'email',\n            {\n              initiaValue: email,\n              rules: [\n                {\n                  required: true,\n                  message: '邮箱不能为空',\n                },\n                {\n                  validator: this.checkMail.bind(this),\n                },\n              ],\n            },\n          ]"}],attrs:{"size":"large"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"label":"验证码","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'verifcode',
            {
              initiaValue: _vm.verifcode,
              rules: [
                {
                  required: true,
                  message: '验证码不能为空',
                },
                {
                  validator: this.checkVerifcode.bind(this),
                },
              ],
            },
          ]),expression:"[\n            'verifcode',\n            {\n              initiaValue: verifcode,\n              rules: [\n                {\n                  required: true,\n                  message: '验证码不能为空',\n                },\n                {\n                  validator: this.checkVerifcode.bind(this),\n                },\n              ],\n            },\n          ]"}],attrs:{"size":"large"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"qrcode"},slot:"prefix"}),(_vm.second === 0)?_c('a-button',{attrs:{"slot":"suffix","type":"link"},on:{"click":_vm.send_mail},slot:"suffix"},[_vm._v("获取验证码")]):_c('span',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v(_vm._s(_vm.second)+"秒后重发")])],1)],1),_c('a-form-item',{attrs:{"wrapper-col":{ offset: 11, span: 2 }}},[_c('a-button',{attrs:{"type":"primary","size":"large","block":""},on:{"click":_vm.emailLogin}},[_vm._v(" 登录 ")])],1)],1)],1):_vm._e(),_c('div',{staticStyle:{"margin-top":"30px","text-align":"center"}},[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.$router.push('/user/register')}}},[_vm._v("没有账号?前往注册")])],1),_c('div',{staticStyle:{"margin-top":"50px","text-align":"center"}},[_c('a-button',{attrs:{"type":"link"},on:{"click":_vm.toBeiAn}},[_vm._v("陕ICP备2020014834号")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }